@import url(https://fonts.googleapis.com/css?family=Lato);@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);@charset "UTF-8";

.navbar-light {
    background-color: unset;
}

.nav-item.active {
    border-top: 3px solid #2aa8cc;
}

/*Graphicsetal styles*/


#g_et_al_logo_img {
    height: 25vh;
    margin: 10px;
}

/*Hide the text on smaller devices*/
@media (max-width: 992px) {
    .navbar-left {
        opacity: 0.2;
    }
}

/*modal waiting*/
/*.load-spinner .modal-dialog{*/
/*    display: table;*/
/*    position: relative;*/
/*    margin: 0 auto;*/
/*    top: calc(33% - 24px);*/
/*}*/

.load-spinner .modal-dialog .modal-content {
    background-color: transparent;
    border: none;
    /*position: fixed;*/
    /*z-index: 9999;*/
    /*width: 100%;*/
    /*height: 100%;*/

    /*background: rgba(0,0,0,0.6);*/
}

/*Graphicsetal styles*/

#g_et_al_logo_img_logged_in {
    height: 10vh;
    margin: 10px;
}

/*Hide the text on smaller devices*/
@media (max-width: 991.98px) {
    #g_et_al_logo_img_logged_in {
        display: none;
    }
}

#g_et_al_logo_text {
    margin: auto;
    padding-left: 0px;
    padding-bottom: 30px
}

.g_et_al_title {
    font-family: "Montserrat", sans-serif;
    font-size: 48pt;
    color: #2aa8cc;
}

.g_et_al_logo {
    font-family: "Lato", sans-serif;
    font-weight: bolder;
    font-size: 24pt;
    color: #000000;
}

.g_et_al_tagline {
    font-family: "Lato", sans-serif;
    font-size: 18pt;
    color: #000000;
}

.g_et_al_navigation {
    font-family: "Lato", sans-serif;
    font-weight: lighter;
    font-size: 12pt;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    color: #000000;
}

.g_et_al_header1 {
    font-family: "Lato", sans-serif;
    font-weight: bolder;
    line-height: 2rem;
    font-size: 22pt;
    color: #2aa8cc;
}

.g_et_al_header2 {
    font-family: "Lato", sans-serif;


    font-size: 20pt;
    color: #2aa8cc;
}

.g_et_al_header3 {
    font-family: "Lato", sans-serif;
    font-size: 14pt;
    color: #2aa8cc;
    font-weight: lighter;

}

.g_et_al_paragraph1 {
    font-family: "Lato", sans-serif;
    font-size: 14pt;
    font-weight: normal;
    color: #000000;
}

.g_et_al_paragraph2 {
    font-family: "Lato", sans-serif;
    font-size: 12pt;
    font-weight: normal;
    color: #000000;
}

.g_et_al_paragraph3 {
    font-family: "Lato", sans-serif;
    font-size: 10pt;
    font-weight: lighter;
    line-height: 2rem;
    color: #2aa8cc;
}

.g_et_al_timecell {
    font-family: "Lato", sans-serif;
    font-size: 10pt;
    font-weight: lighter;
    font-style: italic;
    /*line-height: 2rem;*/
    color: gray;

}

.g_et_al_listtitle {
    font-family: "Lato", sans-serif;
    font-size: 14pt;
    color: #2aa8cc;
}

.g_et_al_listtext {
    font-family: "Lato", sans-serif;
    font-size: 9pt;
    /*font-weight: lighter;*/
    font-style: italic;
    /*line-height: 2rem;*/
    color: gray;

}

.fill-height {
    flex-grow: 1;
    width: 100%;
}


.pricing_col {
    border: 1px solid #2aa8cc;
    box-shadow: rgb(0, 0, 0) 0px 1px 4px 0px;
    margin-left: 10px;
    margin-right: 10px;
}

tr .message_unread {
    background-color: #2aa8cc;
}

.message_read {

}


